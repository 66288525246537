import React from 'react';
import youtube from './../../assets/images/youtube.png';
import footerImg1 from './../../assets/images/footernew_1.png';
import footerImg2 from './../../assets/images/footernew_2.png';
import styles from './Footer.module.scss';

import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div className="app-container">
      <div className={styles['footer-content']}>
        <div className={styles['institutional-box']}>
          <h3 className={styles['title']}>Institucional</h3>
          <p className={styles['about']}>
            <Link to="/sobre">Sobre a Secretaria Executiva do PPPZCM</Link>
          </p>
          <div>
            <a href="https://www.funbea.org.br/anppea/" target="_blank" rel="noopener noreferrer">
              <img src={footerImg1} className={styles['img1']} alt="ANPPEA" />
            </a>
            <a href="https://www.giz.de" target="_blank" rel="noopener noreferrer">
              <img src={footerImg2} className={styles['img2']} alt="GIZ" />
            </a>
          </div>
        </div>

        <div className={styles['social-media-box']}>
          <h3 className={styles['title']}>Siga</h3>
          <div className={styles['social-media']}>
            <a
              href="https://www.youtube.com/channel/UCvJnc_eQtgvrFL2vjBaHHTA/videos"
              target="_blank"
              rel="noopener noreferrer"
              className={styles['social-media-item']}
            >
              <img className={styles['yt-logo']} src={youtube} alt="youtube" />
              <span>YouTube</span>
            </a>
          </div>

          {/* <a href="https://www.instagram.com/fundobrasileiroea/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="instagram" /></a> 
          <a href="https://www.facebook.com/funbeabrasil/" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="facebook" /></a> */}
        </div>
      </div>
    </div>
  );
}
