import numeral from 'numeral';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CssBaseline, GlobalStyles } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
/* styles */
import theme from './styleguide/theme';
/* components */
import App from './App';
/* import reportWebVitals from './reportWebVitals'; */
import './assets/styles/global.scss';

numeral.register('locale', 'pt-br', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  currency: {
    symbol: 'R$',
  },
});
numeral.locale('pt-br');

const client = new QueryClient({
  defaultOptions: { queries: { retry: 1, keepPreviousData: true } },
});

/* ReactDOM.render is no longer supported in React 18. Use createRoot instead */
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <QueryClientProvider client={client} id="container">
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={{ body: { background: theme.palette.beige.gradient } }} />
      <App />
    </ThemeProvider>
  </QueryClientProvider>,
);

/* reportWebVitals(console.log); */
