/* libs */
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { useDorothy } from 'dorothy-dna-react';
import axios from 'axios';

/* ui */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Switch,
  FormGroup,
} from '@mui/material';

/* components */
import ConfirmationDialog from '../../components/ConfirmationDialog';
import AsyncAutocomplete from '../../components/AsyncAutocomplete';

/* styles  */
import styles from './menu_manager.module.scss';

const emptyEntity = {
  title: '',
  link: '',
  type: 'none',
  content_id: 0,
  blank: false,
};

export default function MenuManager({ isAdm, menuId, menuChildId, hasChildren, onClose, onSave }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { server } = useDorothy();
  const [entity, _entity] = useState(emptyEntity);
  const [originalEntity, _originalEntity] = useState(emptyEntity);

  const [editing, _editing] = useState(false);
  const [errors, _errors] = useState({});
  const [confirm, _confirm] = useState(false);
  const [confirmRemove, _confirmRemove] = useState(false);
  const [menuType, _menuType] = useState('none');

  const queryClient = useQueryClient();

  const pageTitle = menuId === 'novo' ? 'Novo menu' : menuChildId === 'novo' ? 'Novo sub menu' : 'Editar sub menu';

  const { data: parent } = useQuery(`menu/${menuId}`, {
    enabled: !!menuId && menuId !== 'novo',
  });

  const { data: child } = useQuery(`menu/${menuChildId}`, {
    enabled: !!menuChildId && menuChildId !== 'novo',
  });

  const mutation = {
    save: useMutation(
      menu => {
        if (entity.id) {
          /* edit */
          return axios.put(`${server}menu/${entity.id}`, { entity: menu });
        } else {
          /* insert */
          return axios.post(`${server}menu`, { entity: menu });
        }
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(`menu/${menuId}`);
          queryClient.invalidateQueries(`menu/${menuChildId}`);
        },
      },
    ),
    remove: useMutation(
      menu => {
        /* edit */
        return axios.delete(`${server}menu/${entity.id}`);
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(`menu/${menuId}`);
          queryClient.invalidateQueries(`menu/${menuChildId}`);
        },
      },
    ),
  };

  useEffect(() => {
    if (!isAdm) return;

    if (isAdm === false) {
      onClose('not permitted');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdm]);

  useEffect(() => {
    _editing(false);
    _errors({});

    _entity(emptyEntity);
  }, [menuId]);

  useEffect(() => {
    if (!parent) return;

    _entity(parent);
    _originalEntity(parent);
    _menuType(parent.type);

    if (!menuChildId) return;

    let childEntity = menuChildId === 'novo' ? emptyEntity : child;
    if (childEntity) {
      childEntity['menu_parent_id'] = menuId;

      _entity(childEntity);
      _originalEntity(childEntity);
      _menuType(childEntity.type);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent, child, menuChildId]);

  const handleSave = async () => {
    _editing(false);

    let hasErrors = false;
    let newErrors = {};

    if (!entity.title) {
      newErrors.title = true;
      hasErrors = true;
    }

    if (menuType === 'page' && !entity.content_id) {
      newErrors.content_id = true;
      hasErrors = true;
    }

    if (menuType === 'link' && !entity.link) {
      newErrors.link = true;
      hasErrors = true;
    }

    if (hasErrors) {
      _errors(newErrors);
      return;
    }

    const snackKey = enqueueSnackbar('Gravando...', {
      persist: true,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    });

    /* save */
    try {
      const { data: response } = await mutation.save.mutateAsync({ entity, isAdm });

      console.log('response', response);

      // onSave(!_.isEqual(originalEntity, entity));

      onSave(response?.[0]);

      closeSnackbar(snackKey);

      enqueueSnackbar('Menu adicionado com sucesso!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    } catch (error) {
      closeSnackbar(snackKey);

      console.error(error);

      enqueueSnackbar('Erro ao gravar o menu!', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  };

  const handleClose = () => {
    /* verificar se ha alteracao */
    if (!_.isEqual(originalEntity, entity)) {
      _confirm(true);
      return;
    }

    onClose();
  };

  const handleConfirmation = response => {
    _confirm(false);
    if (response === 'confirm') onClose();
  };

  const handleRemoveConfirmation = async response => {
    _confirmRemove(false);
    if (response === 'confirm') {
      const snackKey = enqueueSnackbar('Removendo...', {
        persist: true,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });

      /* remove */
      try {
        const { data: response } = await mutation.remove.mutateAsync({ entity, isAdm });

        console.log('response', response);

        onClose();

        closeSnackbar(snackKey);

        enqueueSnackbar('Menu removido com sucesso!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } catch (error) {
        closeSnackbar(snackKey);

        console.error(error);

        enqueueSnackbar('Erro ao remover o menu!', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }

    }
  }

  const handleFieldChange = field => value => {
    _editing(true);

    if (field === 'type') _menuType(value);

    let newEntity = { ...entity, [field]: value };

    console.log(`changing ${field}:`, value, newEntity);
    _entity(newEntity);
  };

  return (
    <div>
      <Dialog
        id="menu_modal"
        className={''}
        open={isAdm}
        onClose={() => onClose()}
        maxWidth="xs"
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{pageTitle}</DialogTitle>
        <DialogContent dividers={true}>
          <div className="row">
            <div className="col-xs-6">
              <TextField
                className="input-text"
                label="Título"
                value={entity.title || ''}
                onChange={e => handleFieldChange('title')(e.target.value)}
                error={!editing && errors.title}
              />
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-xs-12">
              <FormLabel id="select-menu">Tipo de menu:</FormLabel>
              <RadioGroup
                aria-labelledby="select-menu"
                onChange={e => handleFieldChange('type')(e.target.value)}
                name="menu-type-group"
                value={menuType}
              >
                <div className={`row ${styles.radio_item}`}>
                  <FormControlLabel
                    value={'none'}
                    control={<Radio />}
                    label={'nenhum'}
                    className={`${styles.radio_input}`}
                  />
                </div>
                <div className={`row ${styles.radio_item}`}>
                  <FormControlLabel
                    value={'link'}
                    control={<Radio />}
                    label={'link'}
                    className={`${styles.radio_input}`}
                  />
                </div>
                <div className={`row ${styles.radio_item}`}>
                  <FormControlLabel
                    value={'page'}
                    control={<Radio />}
                    label={'page'}
                    className={`${styles.radio_input}`}
                  />
                </div>
              </RadioGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              {'link' === menuType && (<>
                <TextField
                  className="input-text"
                  label="Link"
                  shrink="false"
                  value={entity.link || ''}
                  onChange={e => handleFieldChange('link')(e.target.value)}
                  error={!editing && errors.link}
                />

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!entity.blank}
                        onChange={e => handleFieldChange('blank')(e.target.checked)}
                      />
                    }
                    label="abrir em nova aba"
                  />
                </FormGroup>
              </>)}
              {'page' === menuType && (
                <>
                  <AsyncAutocomplete
                    label="Conteúdos de página"
                    url="content/related"
                    urlSingle="content"
                    titleField="title"
                    query={`?type=page`}
                    onChange={value => handleFieldChange('content_id')(value)}
                    value={entity.content_id}
                    error={!editing && errors.content_id}
                  />
                </>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {!hasChildren && menuId !== 'novo' && menuChildId !== 'novo' && <Button onClick={() => _confirmRemove(true)}>remover</Button>}
          <Button onClick={() => handleSave()}>gravar</Button>
          <Button onClick={() => handleClose()}>cancelar</Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        open={confirm}
        content="Você deseja sair sem gravar as alterações?"
        confirmButtonText="Descartar alterações"
        onClose={handleConfirmation}
      />

      <ConfirmationDialog
        open={confirmRemove}
        content="Você deseja remover este item de menu?"
        confirmButtonText="Remover"
        onClose={handleRemoveConfirmation}
      />
    </div>
  );
}
