import { useState, useEffect } from 'react';
import { useDorothy, /* useRouter */ useUser } from 'dorothy-dna-react';
import { BrowserRouter as Router, Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';

/* components */
import Header from './components/Header';
import Footer from './components/Footer';
import Project from './components/Project';
import NewsRenderer from './components/NewsRenderer';
import PageRenderer from './components/PageRenderer';

/* mui */
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

/* pages */
import AboutPage from './pages/About';
import NewsPage from './pages/News';
import HomePage from './pages/Home';
import FacilitatorsPage from './pages/Facilitators';
import { useQuery } from 'react-query';

/* constants */
const key = 'pppzcm-dorothy-token'; // TODO: lib? env?

export default function Body() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { server } = useDorothy();
  const { user } = useUser();
  const { pathname, hash, key: locationKey } = useLocation();

  const [participateId, _participateId] = useState(null);
  const [showParticipateLoginDialog, _showParticipateLoginDialog] = useState(0);
  const [isLogged, _isLogged] = useState(null);
  const [isAdmOrMod, _isAdmOrMod] = useState(false);
  const [editMode, _editMode] = useState(false);

  useEffect(() => {
    _isLogged(!!localStorage.getItem(key));
  }, []);

  const logout = () => {
    localStorage.removeItem(key);
    _isLogged(false);
  };

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, locationKey]);

  useEffect(() => {
    if (!user) return;
    let userObj = user.membership.find(membership => membership.alias === 'adm');

    _isAdmOrMod(!!userObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const toggleEditMode = () => {
    _editMode(!editMode);
  };

  const handleParticipate = (id, totalMembers) => {
    _participateId(id);

    if (!isLogged) _showParticipateLoginDialog(1);
    else if (totalMembers > 0) doParticipate(id, false);
    else _showParticipateLoginDialog(2);
  };

  const loginSignup = isSignup => {
    _showParticipateLoginDialog(0);

    isSignup
      ? (window.location = `/colabora/cadastro/?portal=/sobre/projeto/${participateId}`)
      : (window.location = `/colabora/login/?portal=/sobre/projeto/${participateId}`);
  };

  const doParticipate = async (id, isADM) => {
    const snackKey = enqueueSnackbar('Enviando pedido de participação...', {
      persist: true,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    });

    const { data } = await axios.post(
      `${server}project/${id}/participate`,
      {
        isADM,
      },
      {
        headers: {
          'X-Dorothy-Token': localStorage.getItem(key),
        },
      },
    );

    closeSnackbar(snackKey);

    if (data.success) {
      enqueueSnackbar('Seu pedido de participação foi enviado! Uma notificação será enviada, no momento da aprovação', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    } else {
      if (data.error === 'already_member') {
        enqueueSnackbar('Você já é membro deste grupo!', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else if (data.error === 'already_in_list') {
        enqueueSnackbar('Você já está na lista de aprovação deste grupo!', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar('Erro ao enviar seu pedido de participação! Por favor, entre em contato com a administração', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }

    _showParticipateLoginDialog(0);
  };

  /* const handleModalClose = message => {
    const notPermitted = message === 'not permitted';
    notPermitted &&
      enqueueSnackbar('Você não tem permissão para acessar essa página!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
    notPermitted ? setTimeout(() => (window.location = '/'), 1500) : (window.location = '/');
  }; */

  function NoMatch() {
    /*     let location = useLocation();

    console.log('location', location);*/

    return <>Página não encontrada!</>;
  }

  return (
    <Router>
      <div className="content">
        <Header
          logout={logout}
          isLogged={isLogged}
          isAdm={isAdmOrMod}
          dynamicMenu={true}
          editMode={editMode}
          toggleEditMode={toggleEditMode}
        />

        <Switch>
          <Route path="/" exact>
            <HomePage onParticipate={handleParticipate} />
          </Route>

          {/* <Route
            path={['/menu/:menu_id', '/submenu/:menu_id/:menu_child_id']}
            render={({
              match: {
                params: { menu_id, menu_child_id },
              },
            }) => {
              return (
                <>
                  <MenuManager
                    isAdm={isAdmOrMod}
                    menuId={menu_id}
                    menuChildId={menu_child_id}
                    onSave={res =>
                      !!res
                        ? res.type === 'page'
                          ? (window.location = `/page/${res.content_id}`)
                          : (window.location = '/')
                        : (window.location = '/')
                    }
                    onClose={message => handleModalClose(message)}
                  />
                </>
              );
            }}
          /> */}

          <Route
            path={['/page/:content_id']}
            render={({
              match: {
                params: { content_id },
              },
            }) => {
              return <GetContent content_id={content_id} />;
            }}
          />

          <Route
            path={['/news/:content_id']}
            render={({
              match: {
                params: { content_id },
              },
            }) => {
              return <GetContent content_id={content_id} />;
            }}
          />

          <Route path="/sobre" exact component={AboutPage} />
          <Route path="/noticias" exact component={NewsPage} />
          <Route path="/facilitadores" exact component={FacilitatorsPage} />
          <Route path="/login">
            <Redirect to="/colabora" />
          </Route>

          <Route
            path="/sobre/projeto/:id"
            exact
            render={({
              match: {
                params: { id },
              },
            }) => {
              return (
                <>
                  <Project id={id} onParticipate={handleParticipate} />
                </>
              );
            }}
          />

          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>

        <div className="footer-separator"></div>
        <Footer />

        <div className="device-not-supported"></div>
        <Dialog
          open={showParticipateLoginDialog !== 0}
          onClose={() => _showParticipateLoginDialog(0)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">
            {showParticipateLoginDialog === 1 ? 'Você precisa estar cadastrado na plataforma!' : 'Participar'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {showParticipateLoginDialog === 1 && (
                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                  <button className="button-primary" variant="contained" onClick={() => loginSignup(false)}>
                    Já tenho um cadastro
                  </button>
                  <button className="button-primary" variant="outlined" onClick={() => loginSignup(true)}>
                    Quero me cadastrar
                  </button>
                </div>
              )}

              {showParticipateLoginDialog === 2 && (
                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                  <button
                    className="button-primary"
                    variant="contained"
                    onClick={() => doParticipate(participateId, false)}
                  >
                    Quero colaborar
                  </button>
                  <button
                    className="button-primary"
                    variant="outlined"
                    onClick={() => doParticipate(participateId, true)}
                  >
                    Sou o responsável pela ação
                  </button>
                </div>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="button-primary" onClick={() => _showParticipateLoginDialog(0)}>
              Fechar
            </button>
          </DialogActions>
        </Dialog>
      </div>
    </Router>
  );
}

const GetContent = ({ content_id }) => {
  const { server } = useDorothy();

  const { data: content, isLoading } = useQuery(['content_text', { content_id }], {
    queryFn: async () => (await axios.get(`${server}content/${content_id}`)).data,
    enabled: !!content_id,
  });

  return (
    <>
      {content && content.type === 'page' && <PageRenderer content={content} />}
      {content && content.type === 'news' && <NewsRenderer content={content} />}
      {!content && <>Página não encontrada</>}
      {isLoading && <>Carregando...</>}
    </>
  );
};
