/* react,libs */
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as dayjs from 'dayjs';

/* styles */
import styles from './news_renderer.module.scss';

/* components */
import ContentRenderer from '../../components/ContentRenderer';

export default function NewsRenderer({ content }) {
  const [categories, _categories] = useState('');
  useEffect(() => {
    document.title = content.title;
    _categories(
      content?.categories
        .filter(x => !!x.name)
        .map(x => x.name)
        ?.join(', '),
    );
  }, [content]);
  return (
    <div className="app-container">
      {content ? (
        <>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Portal</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {content.title}
            </li>
          </ol>

          <div className={styles['news-image-box']}>
            <img src={content.featured_images} alt="banner da noticia" />
          </div>

          <h1 className={`my-4 ${styles['news-title']}`}>{content.title}</h1>

          <table className={`mb-5 ${styles['news-info-table']}`}>
            <thead>
              <tr>
                <th>data de publicação</th>
                <th>categoria</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{dayjs(content.createdAt).format('DD/MM/YYYY')}</td>
                <td>{categories ?? '-'}</td>
              </tr>
            </tbody>
          </table>

          <div className={styles['news-content']} /* dangerouslySetInnerHTML={{ __html: news.body }} */>
            <ContentRenderer text={content.text} nocontainer />
          </div>
        </>
      ) : (
        <p>Esta notícia não pode ser exibida pois pode ter sido excluída ou a url informada está incorreta.</p>
      )}
    </div>
  );
}
