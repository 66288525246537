/* libs */
import axios from 'axios';
import { useDorothy } from 'dorothy-dna-react';
import { LogIn, LogOut } from 'react-feather';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

/* components */
import DynamicMenu from './DynamicMenu';

/* styles */
import styles from './header.module.scss';

export default function Header({ isLogged, logout, isAdm, toggleEditMode, editMode = false, dynamicMenu = false }) {
  const { server } = useDorothy();

  const redirectTo = relative_path => {
    window.location = `/${relative_path || ''}`;
  };

  const { data: menuItems } = useQuery(['menu_items'], {
    queryFn: async () => (await axios.get(`${server}menu/`)).data,
  });

  return (
    <>
      <div className={styles['root']}>
        <div className={styles.header_start}>
          <Link to="/">
            <img className={styles['logo']} src="/logo.png" alt="logomarca escrita 'Monitora EA'" />
          </Link>
        </div>

        {!dynamicMenu && (
          <ul className={styles.header_center}>
            <a className={styles['styled-raw-link']} href="/sobre">
              <li className={styles['menu-item']}>sobre</li>
            </a>
            <a className={styles['styled-raw-link']} href="/#homeDash">
              <li className={styles['menu-item']}>projetos</li>
            </a>
            <a className={styles['styled-raw-link']} href="/facilitadores">
              <li className={styles['menu-item']}>facilitadores</li>
            </a>
            <a className={styles['styled-raw-link']} href="/#news">
              <li className={styles['menu-item']}>notícias</li>
            </a>
            <a className={styles['styled-raw-link']} href="/contato">
              <li className={styles['menu-item']}>contato</li>
            </a>
          </ul>
        )}

        {dynamicMenu && <DynamicMenu editMode={editMode} menuItems={menuItems} />}

        <div className={styles.header_end}>
          {isLogged !== null && !isLogged && (
            <>
              <button onClick={() => redirectTo('colabora')} type="button" className={styles['button-link']}>
                <LogIn /> Entrar
              </button>

              <button onClick={() => redirectTo('colabora/cadastro')} className={styles['button-sign']}>
                Cadastrar
              </button>
            </>
          )}

          {isLogged && (
            <>
              {isAdm && (
                <button onClick={toggleEditMode} className={styles['button-link']}>
                  {editMode ? `Finalizar edição` : `Editar menu`}
                </button>
              )}

              <button onClick={() => redirectTo('colabora')} className={styles['button-sign']}>
                Meus grupos de trabalho
              </button>

              <button type="button" className={styles['button-link']} onClick={logout}>
                <LogOut /> sair
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
