import { useState, useEffect, createRef } from 'react';
import randomColor from 'randomcolor';
import { Map, TileLayer, GeoJSON, Tooltip } from 'react-leaflet';
/* styles */
import styles from './ProjectGeo.module.scss';

/*  */
const mapRef = createRef();
const position = [-15.559793, -56.58506];
const zoom = 4;
const colors = randomColor({ luminosity: 'dark', count: 15 });

export default function ProjectGeo({ loading, pas, bounds }) {
  const [local_bounds, _local_bounds] = useState(null);

  useEffect(() => {
    _local_bounds(bounds);
  }, [bounds]);

  useEffect(() => {
    local_bounds && mapRef && mapRef.current && mapRef.current.leafletElement.fitBounds(local_bounds);
  }, [local_bounds]);

  return (
    <>
      <div className="card mb-3 d-flex">
        <div className="card-body">
          <div className="map-container" style={{ height: '600px' }}>
            <Map center={position} zoom={zoom} ref={mapRef} maxZoom={18} minZoom={3}>
              <TileLayer
                attribution='<a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />

              {pas &&
                pas.map(({ id, geojson, mungeo }, idx) => (
                  <GeoJSON
                    style={{
                      weight: 2,
                      color: '#fff',
                      fillColor: colors[idx % colors.length],
                      fillOpacity: 0.7,
                    }}
                    key={id}
                    data={JSON.parse(geojson)}
                  >
                    {mungeo && (
                      <Tooltip sticky>
                        #{idx} {mungeo}
                      </Tooltip>
                    )}
                  </GeoJSON>
                ))}

              {loading && (
                <div className={styles['loading-container']}>
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  Carregando...
                </div>
              )}
            </Map>
          </div>
        </div>
      </div>
    </>
  );
}
