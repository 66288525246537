import { useEffect, useState, createRef } from 'react';
import { Map, TileLayer, WMSTileLayer, Popup } from 'react-leaflet';
import axios from 'axios';
import L from 'leaflet';
import ReactPaginate from 'react-paginate';
import makeAnimated from 'react-select/animated';

/* components */
import Dialog from '../Dialog';
import {
  reactSelectClassNamePrefix,
  StyledAsyncReactSelect,
  StyledReactSelect,
} from '../../components/StyledReactSelect';
/* styles */
import styles from './ProjectList.module.scss';

/*  */
const animatedComponents = makeAnimated();
const mapRef = createRef();
const position = [-15.559793, -56.58506];
const zoom = 4;
const selectDefaults = {
  placeholder: 'selecione...',
  noOptionsMessage: () => 'Nenhuma opção encontrada!',
  loadingMessage: () => 'Carregando...',
};

function prepareFilters(filters) {
  let preparedFilters = '';

  for (let filter in filters) {
    if (filters[filter]) preparedFilters = `${preparedFilters}&f_${filter}=${filters[filter]}`;
  }

  return preparedFilters;
}

async function getUFs(filters) {
  const { data } = await axios.get(`${process.env.REACT_APP_SERVER}project/ufs?none=1${prepareFilters(filters)}`);

  return data;
}

async function getClickedFeatureId(_map, latlng) {
  const _url = process.env.REACT_APP_GEOSERVER_URL;

  // Construct a GetFeatureInfo request URL given a point
  var point = _map.latLngToContainerPoint(latlng, _map.getZoom()),
    size = _map.getSize(),
    params = {
      request: 'GetFeatureInfo',
      service: 'WMS',
      srs: 'EPSG:4326',
      transparent: true,
      version: '1.1.1',
      format: 'image/png',
      bbox: _map.getBounds().toBBoxString(),
      height: size.y,
      width: size.x,
      layers: 'pppzcm:proj_atuacao',
      query_layers: 'pppzcm:proj_atuacao',
      info_format: 'application/json',
      x: Math.round(point.x),
      y: Math.round(point.y),
      feature_count: 20,
    };

  const getFeatureInfoURL = _url + L.Util.getParamString(params, _url, true);

  /* console.log(getFeatureInfoURL) */

  return await new Promise((resolve, reject) =>
    fetch(getFeatureInfoURL)
      .then(response => response.json())
      .then(data => {
        const err = typeof data === 'object' ? null : data;
        if (err) reject(err);
        else if (!data.features.length) resolve(null);
        else {
          const [table] = data.features[0].id.split('.');
          const ids = data.features.map(f => f.properties.project_id);

          resolve({
            table,
            ids: [...new Set(ids)], // remove duplicates,
          });
        }
      }),
  );
}

export default function ProjectList({ onParticipate }) {
  const [projects, _projects] = useState(null);
  const [projects_ids, _projects_ids] = useState(null);

  const [modalidades, _modalidades] = useState(null);
  const [linhas_acao, _linhas_acao] = useState(null);
  const [regioes, _regioes] = useState(null);
  const [ufs, _ufs] = useState(null);

  const [pag, _pag] = useState(null);
  const [currentPage, _currentPage] = useState(1);

  const [loading, _loading] = useState(false);

  const [filters, _filters] = useState({});
  const [fields, _fields] = useState({
    modalidades: null,
    linhas_acao: null,
    regioes: null,
    ufs: null,
    municipios: null,
    id: null,
  });

  const [showList, _showList] = useState(false);

  const [clickedPosition, _clickedPosition] = useState(null);
  const [showPop, _showPop] = useState(null);
  const [clickedProjects, _clickedProjects] = useState(null);
  const [projectsToView, _projectsToView] = useState([]);
  const [showViewProjectsDialog, _showViewProjectsDialog] = useState(false);

  const [bbox, _bbox] = useState(false);

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    async function fetchData(page = 1) {
      if (Object.keys(filters).filter(k => !!filters[k]).length === 0) {
        _showList(false);
        return;
      } else _showList(true);

      _loading(true);
      const {
        data: { projects: p, pages, hasPrevious, hasNext, currentPage },
      } = await axios.get(`${process.env.REACT_APP_SERVER}project/?page=${page}${prepareFilters(filters)}`);
      _loading(false);

      _projects(p);
      _pag({ pages, hasPrevious, hasNext });
      _currentPage(currentPage);
    }

    fetchData(currentPage, filters);
  }, [currentPage, filters]);

  useEffect(() => {
    _currentPage(1);
    _showPop(null);

    async function fetchGeoData() {
      if (Object.keys(filters).filter(k => !!filters[k]).length === 0) {
        _projects_ids(null);
        return;
      }

      const { data } = await axios.get(`${process.env.REACT_APP_SERVER}project/geo/?${prepareFilters(filters)}`);

      _projects_ids(data);
    }

    /* reset zoom and position */
    mapRef && mapRef.current && mapRef.current.leafletElement.setView(position, zoom);
    fetchGeoData(filters);
  }, [filters]);

  useEffect(() => {
    async function fetchUFs() {
      _ufs(await getUFs(filters));
    }

    fetchUFs();
  }, [filters]);

  useEffect(() => {
    if (!clickedPosition) _showPop(null);
    else _showPop(0);
  }, [clickedPosition]);

  useEffect(() => {
    if (!bbox) return;

    const bounds = [
      [bbox.y1, bbox.x1],
      [bbox.y2, bbox.x2],
    ];
    //console.log('focus on', bounds);
    mapRef && mapRef.current && mapRef.current.leafletElement.fitBounds(bounds);
  }, [bbox]);

  const getOptions = async () => {
    const {
      data: { modalidades, linhas_acao, regioes },
    } = await axios.get(`${process.env.REACT_APP_SERVER}project/options`);

    _modalidades(modalidades);
    _linhas_acao(linhas_acao);
    _regioes(regioes);
  };

  const onFilterChange = (type, selectedOption) => {
    _currentPage(1);

    let newFilters;
    let newFields = { ...fields, [type]: selectedOption, id: null };

    if (selectedOption) {
      newFilters = {
        ...filters,
        [type]: selectedOption.map(s => s.value).join(','),
        id: null,
      };
    } else {
      newFilters = { ...filters, [type]: null, id: null };
    }

    if (type === 'regioes') {
      newFields.ufs = null;
      newFilters.ufs = null;
    }
    if (['regioes', 'ufs'].includes(type)) {
      newFields.municipios = null;
      newFilters.municipios = null;
    }

    _fields(newFields);
    _filters(newFilters);
  };

  const onFilterNameChange = selectedOption => {
    _fields({ ...fields, id: selectedOption });

    if (selectedOption) {
      _filters({ ...filters, id: selectedOption.value });
    } else {
      _filters({ ...filters, id: null });
    }
  };

  const onFilterMunicipioChange = selectedOption => {
    _fields({ ...fields, municipios: selectedOption });

    if (selectedOption) {
      const municipios = selectedOption.map(s => s.value);
      _filters({ ...filters, municipios });
    } else {
      _filters({ ...filters, municipios: null });
    }
  };

  const onFilterInstNameChange = selectedOption => {
    // _fields({ ...fields, instituicao: selectedOption });

    if (selectedOption) {
      _filters({ ...filters, instituicao: selectedOption.map(o => o.value) });
    } else {
      _filters({ ...filters, instituicao: null });
    }
  };

  const loadMunicipiosOptions = (inputValue, callback) => {
    axios
      .get(`${process.env.REACT_APP_SERVER}project/municipios/?nome=${inputValue}${prepareFilters(filters)}`)
      .then(function ({ data }) {
        callback(data);
      });
  };

  const viewProject = id => {
    // history.push(`/sobre/projeto/${id}`);
    window.open(`/sobre/projeto/${id}`, '_blank');
  };

  function currentPageVerify() {
    if (pag !== null) {
      return pag.pages === currentPage;
    }
  }

  const handleMapClick = async e => {
    _clickedPosition(e.latlng);

    const data = await getClickedFeatureId(mapRef.current.leafletElement, e.latlng);

    if (!data) {
      _showPop(null);
      return;
    }

    const { ids } = data;
    /* filtrar pelos projetos ativos */
    const projectsToSee = projects_ids ? ids.filter(id => projects_ids.includes(id)) : ids;

    _showPop(projectsToSee.length);
    _clickedProjects(projectsToSee);
  };

  const listProjects = async () => {
    _showPop(0); /* loading again */

    /* pede o id/nome dos projetos clicados */
    const { data } = await axios.get(
      `${process.env.REACT_APP_SERVER}project/getnames/?ids=${clickedProjects.join(',')}`,
    );

    _showPop(null);

    _projectsToView(data);
    _showViewProjectsDialog(true);
  };

  const loadNameOptions =
    (url = 'project/list/') =>
    (inputValue, callback) => {
      axios
        .get(`${process.env.REACT_APP_SERVER}${url}?nome=${inputValue}${prepareFilters(filters)}`)
        .then(function ({ data }) {
          callback(data);
        });
    };

  /* useEffect(() => {
    if (!projects_ids) return;

    console.log(projects_ids);
  }, [projects_ids]) */

  /*
  const handlePage = (page) => {
    _currentPage(page);
  };
  */

  return (
    <>
      <div className="mb-4 rounded-3">
        <div className={styles['resize-title']}>
          <h1 className={styles['search-text-main']}>Pesquisa</h1>

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4">
                <div className="mb-4">
                  <div className="col-md-4 fw-bold">Modalidades</div>
                  {modalidades && (
                    <div>
                      <StyledReactSelect
                        classNamePrefix={reactSelectClassNamePrefix}
                        {...selectDefaults}
                        onChange={selectedOption => onFilterChange('modalidades', selectedOption)}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={modalidades}
                        value={fields['modalidades']}
                      />
                    </div>
                  )}
                  {!modalidades && (
                    <div className="clearfix" style={{ width: 'inherit' }}>
                      <div className="spinner-border spinner-border-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="mb-3 pb-3">
                  <div className="col-md-4 fw-bold">Linhas de ação</div>
                  {linhas_acao && (
                    <div>
                      <StyledReactSelect
                        classNamePrefix={reactSelectClassNamePrefix}
                        {...selectDefaults}
                        onChange={selectedOption => onFilterChange('linhas_acao', selectedOption)}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={linhas_acao}
                        value={fields['linhas_acao']}
                      />
                    </div>
                  )}
                  {!linhas_acao && (
                    <div className="clearfix" style={{ width: 'inherit' }}>
                      <div className="spinner-border spinner-border-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="mb-3 pb-3">
                  <div className="col-md-4 fw-bold">Região</div>
                  {regioes && (
                    <div>
                      <StyledReactSelect
                        classNamePrefix={reactSelectClassNamePrefix}
                        {...selectDefaults}
                        onChange={selectedOption => onFilterChange('regioes', selectedOption)}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={regioes}
                        value={fields['regioes']}
                      />
                    </div>
                  )}
                  {!regioes && (
                    <div className="clearfix" style={{ width: 'inherit' }}>
                      <div className="spinner-border spinner-border-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="mb-3 pb-3">
                  <div className="col-md-4 fw-bold">Estado</div>
                  {ufs && (
                    <div>
                      <StyledReactSelect
                        classNamePrefix={reactSelectClassNamePrefix}
                        {...selectDefaults}
                        onChange={selectedOption => onFilterChange('ufs', selectedOption)}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={ufs}
                        value={fields['ufs']}
                      />
                    </div>
                  )}
                  {!ufs && (
                    <div className="clearfix" style={{ width: 'inherit' }}>
                      <div className="spinner-border spinner-border-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="mb-3 pb-3">
                  <div className="col-md-4 fw-bold">Município</div>
                  <div>
                    <StyledAsyncReactSelect
                      classNamePrefix={reactSelectClassNamePrefix}
                      {...selectDefaults}
                      placeholder="digite..."
                      onChange={selectedOption => onFilterMunicipioChange(selectedOption)}
                      closeMenuOnSelect={false}
                      loadOptions={loadMunicipiosOptions}
                      isClearable
                      isMulti
                      value={fields['municipios']}
                    />
                  </div>
                </div>

                <div className="mb-2 pb-3">
                  <div className="col-md-5 fw-bold">Nome da instituição</div>
                  <div>
                    <StyledAsyncReactSelect
                      classNamePrefix={reactSelectClassNamePrefix}
                      {...selectDefaults}
                      placeholder="digite..."
                      onChange={selectedOption => onFilterInstNameChange(selectedOption)}
                      isMulti
                      closeMenuOnSelect={false}
                      loadOptions={loadNameOptions('project/instiuicao/list/')}
                      isClearable
                      value={fields['instituicao']}
                    />
                  </div>
                </div>

                <div className="mb-2">
                  <div className="col-md-4 fw-bold">Nome do projeto</div>
                  <div>
                    <StyledAsyncReactSelect
                      classNamePrefix={reactSelectClassNamePrefix}
                      {...selectDefaults}
                      placeholder="digite..."
                      onChange={selectedOption => onFilterNameChange(selectedOption)}
                      closeMenuOnSelect={false}
                      loadOptions={loadNameOptions()}
                      isClearable
                      value={fields['id']}
                    />
                  </div>
                </div>
              </div>

              <div className={`col-md-8 ${styles['map-container']}`}>
                <Map center={position} zoom={zoom} ref={mapRef} maxZoom={18} minZoom={3} onClick={handleMapClick}>
                  {showPop !== null && (
                    <Popup onClose={() => _showPop(null)} position={clickedPosition}>
                      {showPop === 0 && <>Carregando...</>}
                      {showPop > 0 && (
                        <button type="button" className="btn btn-primary" onClick={listProjects}>
                          Ver {showPop} projetos
                        </button>
                      )}
                    </Popup>
                  )}

                  <TileLayer
                    attribution='<a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />

                  <WMSTileLayer
                    url={process.env.REACT_APP_GEOSERVER_URL}
                    layers="pppzcm:proj_atuacao"
                    format="image/png"
                    transparent={true}
                    opacity={0.8}
                    cql_filter={projects_ids ? `project_id in (${projects_ids.join(',')})` : 'project_id>0'}
                  />
                </Map>
              </div>
            </div>

            {loading && (
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="spinner-container">
                    <div className="spinner-grow" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {projects && (
              <div className="row mt-4">
                <div className="col-md-12">
                  {projects.length === 0 && (
                    <div className="card">
                      <div className="card-body text-center">Nenhum item encontrado para os critérios selecionados</div>
                    </div>
                  )}
                  {projects.length > 0 && (
                    <div className={styles['styled-table']}>
                      <thead>
                        <tr>
                          <th scope="col">Projeto</th>
                          <th scope="col" className="d-none d-md-table-cell">
                            Instituição
                          </th>
                          <th scope="col" className="d-none d-md-table-cell">
                            Região
                          </th>
                          <th scope="col" className="d-none d-md-table-cell"></th>
                          <th scope="col" className="d-none d-md-table-cell"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {projects.map(({ id, nome, instituicao_nome, regioes, bbox, total_members }) => (
                          <tr key={id}>
                            <td className="text-wrap">
                              <div onClick={() => viewProject(id)}>{nome}</div>
                            </td>
                            <td className="d-none d-md-table-cell">{instituicao_nome}</td>
                            <td className="d-none d-md-table-cell">{regioes.join(', ')}</td>
                            <td className="d-none d-md-table-cell">
                              <div
                                className={`btn btn-outline-primary ${styles['styled-access-button']}`}
                                type="button"
                                onClick={() => viewProject(id)}
                              >
                                Acessar
                              </div>
                            </td>
                            <td className="d-none d-md-table-cell">
                              <div
                                className={`btn btn-outline-primary ${styles['styled-access-button']}`}
                                type="button"
                                onClick={() => onParticipate(id, total_members)}
                              >
                                Participar
                              </div>
                            </td>
                            <td className="d-none d-md-table-cell">
                              {bbox && (
                                <div
                                  className={`btn btn-outline-primary ${styles['styled-access-button']}`}
                                  type="button"
                                  onClick={() => _bbox(bbox)}
                                >
                                  Mapa
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </div>
                  )}
                </div>
                {showList && pag !== null && pag.pages && pag.pages > 1 && (
                  <ReactPaginate
                    previousLabel={'Anterior'}
                    nextLabel={'Próximo'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pag != null && pag.pages}
                    marginPagesDisplayed={2}
                    previousClassName={currentPage !== 1 ? 'page-link previousPage' : styles['disabled-previous']}
                    nextClassName={currentPageVerify() ? styles['disabled-next'] : `page-link nextPage`}
                    pageClassName={`page-link ${styles['disabled-mobile']}`}
                    pageRangeDisplayed={5}
                    onPageChange={e => _currentPage(e.selected + 1)}
                    containerClassName="pagination"
                    subContainerClassName={`pages ${styles['pagination']}`}
                    activeClassName={`${styles['active']}`}
                    forcePage={currentPage - 1}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <Dialog show={showViewProjectsDialog} title="Projetos" onClose={() => _showViewProjectsDialog(false)} big>
        <div className="modal-body">
          <ul>
            {projectsToView &&
              projectsToView.map(({ id, nome }) => (
                <li className="projects-to-view" onClick={() => viewProject(id)} key={id}>
                  {nome}
                </li>
              ))}
          </ul>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={() => _showViewProjectsDialog(false)}>
            Fechar
          </button>
        </div>
      </Dialog>
    </>
  );
}
