/* import { useHistory } from 'react-router-dom';
 */
import { Link } from 'react-router-dom';
/* styles */
import styles from './About.module.scss';
/* components  */

export default function About() {
  /*   const history = useHistory();
   */
  return (
    <>
      {/* <button onClick={() => history.push('/')}>← Voltar</button>
      <br />
    [Sobre] */}
      <div className="app-container">
        <nav aria-label="breadcrumb" className="nav-about">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Portal</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              A Rede de Comunidades de Aprendizagens do PPPZCM
            </li>
          </ol>
          <div className={styles['content']}>
            <div className={styles['title']}>O que é a Rede de Comunidades de Aprendizagem do PPPZCM?</div>
            A Rede de Comunidades de Aprendizagens do PPPZCM se alinha às estruturas de redes horizontais e informais
            pautadas na confiança e cooperação entre seus elos e com objetivos comuns de desenvolver processos críticos
            e estruturantes de capacitação e educação ambiental que contribuam para o uso sustentável e conservação da
            biodiversidade da ZCM.
            <br />
            <br /> A Rede é formada pelos seus elos, por um grupo de facilitação e por uma secretaria executiva:
            <br /> a) Elos da Rede: Todas as pessoas e organizações que registraram suas ações no PPPZCM e outras que
            ainda desejam cadastrar e contribuir de alguma forma para difundir e implementar as diretrizes do PPPZCM.
            <br /> <br />
            b) Grupo de Facilitação da Rede: Cerca de 50 pessoas (organizações/pessoas) participantes diretamente da
            gestão e governança do PPPZCM junto à Secretaria Executiva da Rede.
            <br /> <br /> C) Secretaria Executiva da Rede: Formada pela GIZ e pela ANPPEA (Articulação Nacional de
            Políticas Públicas de Educação Ambiental) que assumem o papel de estruturar e fortalecer a Rede, buscando a
            transição para outras instituições assumirem a partir de 2023.
            <br /> <br />
            Espera-se com a “Rede de Comunidades de Aprendizagens do PPPZCM” a animação de novas ações educativas, do
            fortalecimento de políticas públicas de educação ambiental e criação de sinergias na Zona Costeira e Marinha
            por meio de processos de mobilização, formação, educomunicação, monitoramento e avaliação com base em
            indicadores e ferramentas tecnológicas.
            <br /> <br />
            <div className={styles['videos']}>
              <div>
                <iframe
                  width="533"
                  height="300"
                  src="https://www.youtube.com/embed/YTvA_DfhJXc"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div>
                <iframe
                  width="533"
                  height="300"
                  src="https://www.youtube.com/embed/kEJQ2uG_Bco"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}
