import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
/* components */
import ProjectGeo from '../ProjectGeo';
/* styles */
import styles from './Project.module.scss';

export default function Project({ id, onParticipate }) {
  const [project, _project] = useState(null);
  const [loading, _loading] = useState(false);
  const [pas, _pas] = useState(null);
  const [bounds, _bounds] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER}project/${id}`);
      _project(data);
    }
    fetchData();
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      // carrega todas as atuacoes para este projeto
      _loading(true);

      const {
        data: { atuacoes, bbox },
      } = await axios.get(`${process.env.REACT_APP_SERVER}project/${id}/atuacoes`);

      _loading(false);
      _pas(atuacoes);

      if (bbox && bbox.y1 && bbox.x1 && bbox.y2 && bbox.x2)
        _bounds([
          [bbox.y1, bbox.x1],
          [bbox.y2, bbox.x2],
        ]);
    }
    if (id) fetchData();
  }, [id]);

  return (
    <>
      <div>
        <div className={`mt-2 ${styles['project-container']}`}>
          <div className="container pb-4" id="custom-cards">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Portal</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Projeto
                </li>
              </ol>
            </nav>

            {!project && (
              <div className="clearfix" style={{ width: 'inherit' }}>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Carregando...</span>
                </div>
                <span className="ml-2">Carregando...</span>
              </div>
            )}

            {project && (
              <>
                <h4 className="pb-2 border-bottom">{project.nome}</h4>

                <h6>{project.instituicao_nome}</h6>

                <div className="card mt-4 mb-3">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <table className={styles['info-table']}>
                        <thead>
                          <tr>
                            <th>Modalidade</th>
                            <th>Linhas de atuação</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {project.modalidade_nome && project.modalidade_nome.length > 0 && (
                                <div>{project.modalidade_nome}</div>
                              )}
                            </td>
                            {project.linhas && project.linhas.length > 0 && (
                              <td>
                                {project.linhas.map((l, idx) => (
                                  <div key={l}>
                                    <span className="value">{l}</span>
                                    {idx < project.linhas.length - 1 && (
                                      <span className={styles['separator']}>{','}</span>
                                    )}
                                  </div>
                                ))}
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                      <div>
                        <button
                          className={`btn btn-outline-primary ${styles['styled-access-button']}`}
                          type="button"
                          onClick={() => onParticipate(project.id, project.total_members)}
                        >
                          Quero participar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <ProjectGeo loading={loading} pas={pas} bounds={bounds} />

                <div className="card mt-3 mb-3">
                  <div className="card-body">
                    <h5 className="card-title">Objetivos</h5>
                    <ul className="card-text">
                      {breakItems(project.objetivos_txt).map((txt, idx) => (
                        <li key={idx}>{txt}</li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="card mt-3 mb-3">
                  <div className="card-body">
                    <h5 className="card-title">Aspectos Gerais</h5>
                    <ul className="card-text">
                      {breakItems(project.aspectos_gerais_txt).map((txt, idx) => (
                        <li key={idx}>{txt}</li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="card mt-3 mb-3">
                  <div className="card-body">
                    <h5 className="card-title">Per&iacute;odo</h5>
                    <ul className="card-text">
                      {breakItems(project.periodo_txt).map((txt, idx) => (
                        <li key={idx}>{txt}</li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="card mt-3 mb-3">
                  <div className="card-body">
                    <h5 className="card-title">P&uacute;blicos</h5>
                    <ul className="card-text">
                      {breakItems(project.publico_txt).map((txt, idx) => (
                        <li key={idx}>{txt}</li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="card mt-3">
                  <div className="card-body">
                    <h5 className="card-title">Parceiros</h5>
                    <ul className="card-text">
                      {breakItems(project.parceiros_txt).map((txt, idx) => (
                        <li key={idx}>{txt}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

/* Aux functions */
function breakItems(txt) {
  if (!txt) return [];
  return txt.split('\n').filter(txt => txt.length);
}
