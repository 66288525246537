/* libs */
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDorothy } from 'dorothy-dna-react';
import { useQuery, useQueryClient } from 'react-query';

/* components */
import MenuManager from '../../MenuManager';

/* styles */
import styles from './../header.module.scss';

export default function DynamicMenu({ menuItems, editMode }) {
  const { server } = useDorothy();

  const [open, _open] = useState(false);
  const [current, _current] = useState(null);

  const [subMenu, _subMenu] = useState(null);

  const { data: subMenuItems } = useQuery(['sub_menu_items', current], {
    queryFn: async () => (await axios.get(`${server}menu/${current}/childs`)).data,
    enabled: !!current && current !== 'novo',
  });

  useEffect(() => {
    _subMenu(subMenuItems);
  }, [current, subMenuItems]);

  const toggleOpenMenu = (event, itemId) => {
    _open(event.type === 'mouseover');
    _current(event.type === 'mouseover' ? itemId : null);
  };

  return (
    <>
      {menuItems && (
        <div className={styles.header_center}>
          {menuItems.map(item => (
            <div
              key={`${item.id}`}
              onMouseOver={e => toggleOpenMenu(e, item.id)}
              onMouseOut={e => toggleOpenMenu(e, item.id)}
            >
              <MenuItem
                id={item.id}
                parentId={item.menu_parent_id}
                link={item.type === 'page' ? `/page/${item.content_id}` : item.link}
                blank={item.blank}
                title={item.title}
                editMode={editMode}
                hasChildren={subMenu?.length > 0}
              />

              {open && current === item.id && (
                <div className={`${(subMenu && subMenu.length > 0) || editMode ? styles.menu : ''}`}>
                  {subMenu &&
                    subMenu.length > 0 &&
                    subMenu.map(subMenuItem => (
                      <div key={`${subMenuItem.menu_parent_id}_${subMenuItem.id}`}>
                        <MenuItem
                          parentId={subMenuItem.menu_parent_id}
                          id={subMenuItem.id}
                          link={subMenuItem.type === 'page' ? `/page/${subMenuItem.content_id}` : subMenuItem.link}
                          title={subMenuItem.title}
                          editMode={editMode}
                          blank={subMenuItem.blank}
                        />
                      </div>
                    ))}
                  {editMode && (
                    <>
                      <MenuItem id={`novo`} parentId={item.id} title={'+ novo submenu'} editMode={editMode} />
                    </>
                  )}
                </div>
              )}
            </div>
          ))}

          {editMode && <MenuItem id={`novo`} title={'+ novo menu'} editMode={editMode} />}
        </div>
      )}
    </>
  );
}

const MenuItem = ({ id, parentId, link, blank, title, editMode, hasChildren }) => {
  const [open, _open] = useState(false);

  const queryClient = useQueryClient();

  const handleMenuChange = path => {
    if (!path) return;

    let pathAnchor = path.indexOf('#') >= 0 ? `/${path}` : null;
    if(!pathAnchor && !!blank) window.open(link, '_blank');
    else window.location = pathAnchor ?? path;
  };

  const handleClose = () => {
    queryClient.invalidateQueries(`menu_items`);
    queryClient.invalidateQueries(`sub_menu_items`);
    _open(false);
  };

  return (
    <div className={styles['styled-raw-link']}>
      {editMode && open && (
        <>
          <MenuManager
            isAdm={editMode}
            menuId={parentId ?? id}
            menuChildId={parentId ? id : null}
            hasChildren={hasChildren}
            onSave={() => handleClose()}
            onClose={() => handleClose()}
          />
        </>
      )}
      <div className={styles['menu-item']} onClick={() => (!editMode ? handleMenuChange(link) : _open(true))}>
        {title}
      </div>
    </div>
  );
};
