import { useEffect, useRef } from 'react';

import { Modal } from 'bootstrap';

import classnames from 'classnames';

export default function Dialog({ show, onClose, title, children, big = false }) {
  const modalEl = useRef();
  const modalDialog = useRef();

  useEffect(() => {
    modalDialog.current = new Modal(modalEl.current);
  }, []);

  useEffect(() => {
    if (!modalDialog.current) return;

    if (show) modalDialog.current.show();
    else modalDialog.current.hide();
  }, [show]);

  return (
    <div className="modal fade" ref={modalEl} tabIndex="-1" aria-labelledby="helpModalLabel" aria-hidden="true">
      <div className={classnames('modal-dialog', { 'modal-xl': big })}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="helpModalLabel">
              {title}
            </h5>
            <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
