import './ck-content-styles.css';
import styles from './content_renderer.module.scss';
import sanitize from 'sanitize-html';

export default function ContentRenderer({ text, nocontainer = false }) {
  const sanitizedHtml = sanitize(text, {
    allowedTags: false,
    allowedAttributes: false,
    parseStyleAttributes: false,
  });

  return (
    <div className={nocontainer ? '' : 'app-container'}>
      <div className={styles.content_wrapper}>
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} className={'ck-content'} />
      </div>
    </div>
  );
}
