import React from 'react';
import styles from './Facilitators.module.scss';
import dataJson from './facilitators.json';
import { Link } from 'react-router-dom';

export default function FacilitatorsPage() {
  const DisplayData = dataJson.map(info => {
    return (
      <tr>
        <td>{info.nome}</td>
        <td>{info.email}</td>
        <td>{info.instituiçao}</td>
        <td>{info.estado}</td>
        <td>{info.grupoterritorial}</td>
        <td>
          <div className={styles['image-box']}>
            <img src={info.foto} alt="pessoa sorrindo" />
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div className="app-container">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Portal</Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Facilitadores
        </li>
      </ol>

      <table class="table table-striped">
        <thead>
          <tr>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Instituição</th>
            <th>Estado</th>
            <th>Grupo Territorial</th>
            <th>Foto</th>
          </tr>
        </thead>
        <tbody>{DisplayData}</tbody>
      </table>
    </div>
  );
}
