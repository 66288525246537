import { Link } from 'react-router-dom';
/* styles */
import styles from './Home.module.scss';
/* assets */
import introLogo from './../../assets/images/introLogo.png';
import pdfFile from './../../assets/files/PPPZCM_Atual.pdf';
/* components */
import ProjectList from './../../components/ProjectList';
import ProjectsCharts from './../../components/ProjectsCharts';
import NewsPage from './../../pages/News';
import Download from '../../components/icons/Download';
import ArrowRight from '../../components/icons/ArrowRight';

export default function HomePage({ onParticipate }) {
  return (
    <>
      <div className="app-container">
        <div className={styles.intro_presentation}>
          <div className={styles.presentation_wrapper}>
            <div className={styles.logo}>
              <img
                src={introLogo}
                alt="logo azul escuro do PPPZCM com peixes, canoa, pessoas comemorando e uma tartaruga"
              />
            </div>

            <div className={styles.about}>
              <p>
                O <b>Projeto Político Pedagógico da Zona Costeira e Marinha do Brasil</b> (PPPZCM) é um instrumento de
                gestão de processos educativos da Zona Costeira e Marinha do Brasil com o foco no uso sustentável e
                conservação da biodiversidade. O PPPZCM foi construído de forma participativa, entre 2019 e 2021, pelos
                Projetos TerraMar e Gef-Mar.
              </p>

              <p>
                De acordo com MMA (2005), um Projeto Político Pedagógico (PPP) “nunca é um produto acabado e definitivo
                ou uma cartilha normatizadora. Em linhas gerais um PPP se organiza por meio de três eixos: situacional,
                conceitual e operacional”.
              </p>

              <div>
                <a href={pdfFile} target="_blank" rel="noopener noreferrer">
                  <button type="button" className={`${styles['styled-link']}`}>
                    <Download></Download>
                    Baixe o pdf
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.intro_history}>
        <div className="app-container">
          <div className={styles['how-was-the-project-build-wrapper']}>
            <div className={styles['how-was-the-project-build']}>
              <p>
                <strong className={`${styles['style-news']}`}>Como o PPPZCM foi construído?</strong>
              </p>

              <p>
                O Projeto TerraMar e o Projeto GEF-Mar, ancorados no Ministério do Meio Ambiente (MMA), Instituto Chico
                Mendes de Conservação da Biodiversidade (ICMBio) e GIZ, na perspectiva de promover uma atuação integrada
                e articulada para os processos formativos na Zona Costeira e Marinha, realizaram a construção do Projeto
                Político Pedagógico da Zona Costeira e Marinha (PPPZCM) com o foco no uso sustentável e conservação da
                biodiversidade.
              </p>

              <p>
                O PPPZCM foi elaborado por meio de um processo participativo no período de setembro de 2019 a fevereiro
                de 2021, contando com a participação geral de cerca de 1100 pessoas e 500 organizações comunitárias e
                institucionais.
              </p>
              <p>
                A construção do PPPZCM contemplou estratégias e técnicas variadas para incluir a diversidade de olhares,
                pensares e fazeres da Zona Costeira e Marinha. Não houve a intenção de fazer um “censo demográfico”,
                mas, sim de promover diálogos, escutas e reflexões com as representações dos atores dos territórios da
                ZCM.
              </p>
              <p>
                Destaca-se que uma das estratégias prioritárias de construção do PPPZCM foi o processo formativo junto a
                cerca de 40 pessoas que contribuíram diretamente na construção do PPP, criando-se assim uma rede de
                aprendizagens com integrantes do poder público, organizações não governamentais, povos originários,
                comunidades tradicionais, associações comunitárias e Instituições de Ensino Superior (IES) das várias
                regiões da Zona Costeira e Marinha do Brasil.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.news} id="news">
        <div className="app-container">
          <div className={styles.news_title}>
            <strong className={`${styles['style-news']}`}>Notícias</strong>
            <Link to="/noticias">
              <button type="button" className={`${styles['styled-link']}`}>
                <strong className={`${styles['style-news-all']}`}>
                  Ver todas
                  <ArrowRight></ArrowRight>
                </strong>
              </button>
            </Link>
          </div>
          <NewsPage scrollLock={true} step={4} />
        </div>
      </div>

      <div className={styles['about-project-wrapper']}>
        <div className="app-container">
          <div className={styles.about_project}>
            <p className={styles.platform_text}>
              A <b>Plataforma MonitoraEA PPPZCM</b> é o espaço para o monitoramento e avaliação dos projetos e ações do
              PPPZCM. A Plataforma conecta-se com o Sistema MonitoraEA Nacional – desenvolvido para o monitoramento e
              avaliação de Políticas Públicas de Educação Ambiental em todo o país.
            </p>

            <div className={`${styles.access_wrapper}`}>
              <a href="https://www.monitoraea.org.br" target="_blank" rel="noopener noreferrer">
                <button type="button" className={`${styles['styled-link']}`}>
                  Acessar
                  <ArrowRight></ArrowRight>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="app-container">
        <div className={styles.charts}>
          <ProjectsCharts />
          <ProjectList onParticipate={onParticipate} />
        </div>
      </div>
    </>
  );
}
