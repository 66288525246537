/* react,libs */
import { Link } from 'react-router-dom';

/* styles */
import styles from './page_renderer.module.scss';

/* components */
import ContentRenderer from '../ContentRenderer';

export default function NewsRenderer({ content }) {
  return (
    <div className="app-container">
      {content ? (
        <>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Portal</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {content.title}
            </li>
          </ol>

          <h1 className={`my-4 ${styles['news-title']}`}>{content.title}</h1>

          <div className={styles['news-content']} /* dangerouslySetInnerHTML={{ __html: news.body }} */>
            <ContentRenderer text={content.text} nocontainer />
          </div>
        </>
      ) : (
        <p>Esta página não pode ser exibida pois pode ter sido excluída ou a url informada está incorreta.</p>
      )}
    </div>
  );
}
