import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
/* styles */
import styles from './news_page.module.scss';
/* components */

export default function NewsPage({ scrollLock = false, step = 3 }) {
  const [loading, _loading] = useState(true);
  const [allNews, _allNews] = useState([]);
  const [offset, _offset] = useState(0);
  const [total, _total] = useState(step);
  const [lastElement, _lastElement] = useState(null);

  const observer = useRef(
    new IntersectionObserver(entries => {
      const first = entries[0];
      if (first.isIntersecting) {
        _offset(no => no + step);
      }
    }),
  );

  const getNews = async () => {
    _loading(true);
    let response = await axios.get(`${process.env.REACT_APP_SERVER}news/`, {
      params: { limit: step, offset: offset },
    });
    let all = new Set([...allNews, ...response.data.news]);
    _allNews([...all]);
    _loading(false);
  };

  const getTotal = async () => {
    const response = await axios.get(`${process.env.REACT_APP_SERVER}news/total`);
    _total(response.data.total);
  };

  useEffect(() => {
    if (!scrollLock) getTotal();
    else _total(0);
  }, [scrollLock]);

  useEffect(() => {
    if (offset <= total) {
      getNews();
    }
    //eslint-disable-next-line
  }, [offset]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  useEffect(() => {
    console.log('allNews', allNews);
  }, [allNews]);

  return (
    <div className={!scrollLock ? 'app-container' : ''}>
      <div className={styles['news-grid-box']}>
        {allNews.length > 0 &&
          allNews.map((news, i) => {
            return i === allNews.length - 1 && !loading && offset <= total ? (
              <div key={`${news.id}-${i}`} ref={_lastElement}>
                <NewsBox data={news} />
              </div>
            ) : (
              <NewsBox data={news} key={`${news.id}-${i}`} />
            );
          })}
      </div>
      {loading && <p className="text-center">carregando noticias...</p>}
    </div>
  );
}

const NewsBox = ({ data }) => {
  return (
    <div className={styles['news-box']}>
      <Link to={`/news/${data.id}`}>
        <div>
          <img src={data.featured_images} alt={'banner'} />
        </div>
        <p>{data.title}</p>
      </Link>
    </div>
  );
};
