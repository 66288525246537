import { useState, useEffect } from 'react';
import axios from 'axios';
import ReactECharts from 'echarts-for-react';
import styles from './ProjectsCharts.module.scss';
/* import { useHistory } from 'react-router-dom'; */
import { useQuery } from 'react-query';

export default function ProjectsCharts() {
  /* const history = useHistory(); */

  const [qtdProjetos, _qtdProjetos] = useState(null);
  const [modalidades, _modalidades] = useState(null);
  const [regions, _regions] = useState(null);

  const { data } = useQuery(['dash_list'], {
    queryFn: async () => (await axios.get(`${process.env.REACT_APP_SERVER}dash/home`)).data,
  });

  useEffect(() => {
    if (!data) return;
    _qtdProjetos(data.qtdProjetos);
    _modalidades(data.modalidades);
    _regions(data.regioes);
  }, [data]);

  return (
    <>
      {/* [Home]
    <br />
    <button onClick={()=>history.push('//about')}>Sobre</button>
    <br />
    <button onClick={()=>history.push('/login')}>Login</button> */}
      <div className={styles['resize-title']}>
        <h1>Visão Geral</h1>
      </div>
      <div className={styles['dash-list']} id="homeDash">
        <ProjectsBigNumber data={qtdProjetos} />

        {/* the two OverflowHidden cannot be wrapper in just one OverflowHidden, that is a bug on echarts */}
        <div className={styles['overflow-hidden']}>
          <Modalities data={modalidades} />
        </div>

        <div className={styles['overflow-hidden']}>
          <Regions data={regions} />
        </div>
      </div>
    </>
  );
}

function ProjectsBigNumber({ data }) {
  return (
    <div>
      <div className={`h-100 p-5 ${styles['big-number-container']} ${styles['card-chart']}`}>
        <div className={styles['big-number']} style={{ display: 'flex', alignContent: 'center' }}>
          {!!data && (
            <div className={styles['projects-data']}>
              <span style={{ fontSize: '2.2rem', fontWeight: 'bold' }}>{data}</span>
              <span style={{ fontSize: '1.25rem', marginLeft: '0.2rem' }}>projetos</span>
            </div>
          )}
          {!data && (
            <div className="spinner-container">
              <div className="spinner-grow" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function Modalities({ data }) {
  const [options, _options] = useState(null);

  useEffect(() => {
    function prepare(rawData) {
      const data = rawData.map(({ nome: name, total: value }) => ({
        value,
        name,
      }));

      return {
        textStyle: {
          fontFamily: 'Raleway',
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: '0%',
          left: 'center',
        },
        series: [
          {
            type: 'pie',
            radius: ['10%', '70%'],
            center: ['50%', '37%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,
            },
            label: {
              show: false,
              // position: 'center'
            },
            /* emphasis: {
                            label: {
                                show: true,
                                fontSize: '20',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        }, */
            data,
          },
        ],
        // color: colorPalette,
      };
    }
    if (data) _options(prepare(data));
  }, [data]);

  return (
    <>
      <div className={`h-100 p-5 ${styles['card-chart']}`}>
        <span style={{ fontWeight: 600, fontSize: '1.25rem' }}>Modalidades</span>
        {options && <ReactECharts style={{ width: '100%' }} option={options} />}
        {!options && (
          <div className="spinner-container">
            <div className="spinner-grow" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function Regions({ data }) {
  const [options, _options] = useState(null);

  useEffect(() => {
    function prepare(rawData) {
      const data = rawData.map(({ nome: name, total: value }) => ({
        value,
        name,
      }));

      return {
        textStyle: {
          fontFamily: 'Raleway',
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: '0%',
          left: 'center',
        },
        series: [
          {
            type: 'pie',
            radius: ['10%', '70%'],
            center: ['50%', '37%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,
            },
            label: {
              show: false,
              // position: 'center'
            },
            /* emphasis: {
                            label: {
                                show: true,
                                fontSize: '20',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        }, */
            data,
          },
        ],
        // color: colorPalette,
      };
    }
    if (data) _options(prepare(data));
  }, [data]);

  return (
    <>
      <div className={`h-100 p-5 ${styles['card-chart']}`}>
        <span style={{ fontWeight: 600, fontSize: '1.25rem' }}>Regiões</span>
        <div>
          {options && (
            <div>
              <ReactECharts style={{ width: '100%' }} option={options} />
            </div>
          )}
          {!options && (
            <div className="spinner-container">
              <div className="spinner-grow" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
